// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'; // Import the language detector
import translations from '../assets/locales/translations.js';

const resources = {
    en: {
        translation: Object.keys(translations).reduce((acc, key) => {
            acc[key] = translations[key].en;
            return acc;
        }, {})
    },
        it: {
        translation: Object.keys(translations).reduce((acc, key) => {
            acc[key] = translations[key].it;
            return acc;
        }, {})
    },
        es: {
        translation: Object.keys(translations).reduce((acc, key) => {
            acc[key] = translations[key].es;
            return acc;
        }, {})
    },
        fr: {
        translation: Object.keys(translations).reduce((acc, key) => {
            acc[key] = translations[key].fr;
            return acc;
        }, {})
    },
        de: {
        translation: Object.keys(translations).reduce((acc, key) => {
            acc[key] = translations[key].de;
            return acc;
        }, {})
    }
  };

  i18n
  .use(LanguageDetector) // Use the language detector
  .use(initReactI18next) // Pass i18n to react-i18next
  .init({
    resources, // Use the new resources structure
    fallbackLng: 'en', // Fallback to English
    detection: {
        order: ['querystring', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
        caches: ['localStorage'], // Removed 'cookie' from caches
    },
    interpolation: {
      escapeValue: false // React already prevents XSS
    }
  });

export default i18n;
