import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand } from '@fortawesome/free-solid-svg-icons';

function HoverButton({ className, children, config, onClick, icon ,style, disabled,inset=true,BackgroundColor,...props}) {
    // State to handle hover
    const [isHovered, setIsHovered] = React.useState(false);
    const defaultColors = {
        secondary: "gray", // default background color
        darkSecondary: "black" // default hover background color
    };
    const colors = config.colors || defaultColors;
    const backgroundColor = BackgroundColor || colors.secondary;
    // Style configurations
    // Function to convert hex color to rgba
    const hexToRgba = (hex, alpha,multi=1) => {
        let r = 0, g = 0, b = 0;
        if (hex.length === 4) {
            r = parseInt(hex[1] + hex[1], 16);
            g = parseInt(hex[2] + hex[2], 16);
            b = parseInt(hex[3] + hex[3], 16);
        } else if (hex.length === 7) {
            r = parseInt(hex[1] + hex[2], 16);
            g = parseInt(hex[3] + hex[4], 16);
            b = parseInt(hex[5] + hex[6], 16);
        }
        return `rgba(${Math.min(255,Math.max(0,r*multi))}, ${Math.min(255,Math.max(0,g*multi))}, ${Math.min(255,Math.max(0,b*multi))}, ${alpha})`;
    };

    // Calculate shadow colors
    const shadowColorLight = hexToRgba(backgroundColor, 0.2);
    const hoverShadowColorLight = hexToRgba(backgroundColor, 1);
    const hoverShadowColorDark = hexToRgba(backgroundColor, 0.6,0.4);
    const standardTail = `
    px-4 py-2 rounded-full
    flex items-center gap-2
    text-white
    transition-all
    enabled:hover:text-white
    disabled:bg-slate-400
    disabled:cursor-not-allowed
    transition
    duration-150
    ease-in-out
    active:translate-y-1
    motion-reduce:transition-none
`;
const standardTail0 = `
    px-4 py-2 rounded-full
    flex items-center gap-2
    text-white
    shadow-[-2px_-2px_5px_rgba(214,_255,_111,_0.2),_2px_5px_5px_rgba(79,_94,_41,_0.4)]
    transition-all
    bg-secondary
    enabled:hover:shadow-[inset_-2px_-2px_5px_rgba(214,_255,_111,_1),inset_2px_2px_5px_rgba(79,_94,_41,_0.4)]
    enabled:hover:text-white
    disabled:bg-slate-400
    disabled:cursor-not-allowed
    transition
    duration-150
    ease-in-out
    active:translate-y-1
    motion-reduce:transition-none
`;
const buttonStyle = {
    backgroundColor: disabled ? colors.slate : backgroundColor,
    boxShadow: `-2px -2px 5px ${shadowColorLight}, 2px 2px 5px ${hoverShadowColorDark}`,
};

const hoverStyle = isHovered
    ? {
          boxShadow: `inset -2px -2px 5px ${hoverShadowColorLight}, inset 2px 2px 5px ${hoverShadowColorDark}`,
      }
    : {};
    return (
        <button
        disabled={disabled}
        className={standardTail+className}
        style={{ ...buttonStyle, ...hoverStyle, ...style }}
        onClick={onClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        >
        {icon && <FontAwesomeIcon icon={icon} style={{ marginRight: '0px',marginLeft: '0px',width:'20px'}} />}
        {children}
        </button>
    );
}


export default HoverButton;
