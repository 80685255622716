import React from 'react';
import Card from './Card';
import Stack from './Stack';
import config from '../assets/config'; // Import the config file
import useResponsive from './useResponsive';
import HoverCard from './HoverCard';
import usePublic from './usePublicConfig';
import HoverButton from './HoverButton';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Home() {
  const isMobile = useResponsive()
  const navigate = useNavigate();
  const { t } = useTranslation();
  const projects = usePublic('/content/projects/projectsConfig.json');
  const projectGallery = projects.map((project) => `${process.env.PUBLIC_URL}/content/projects/${project.nameid}/${project.image}`);
  return (
    <Stack direction="v" style={{padding:'0px',marginTop:'10px'}}>

        {/*  //////////////////////////////////       BANNER       /////////////////////////////////////// */}
        {true && (
        <div className="relative w-full h-full flex justify-center items-center shadow-[px_-2px_10px_rgba(255,_255,255,_0.4),_0px_5px_10px_rgba(0,0,0,_0.5)]">
            <img
            src={`${process.env.PUBLIC_URL}/content/banner/banner.png`}
            alt="banner"
            style={{ minHeight: '200px',width: '100%',height: '100%',objectFit: 'cover',objectPosition: 'center' }}
            />
            <div className="absolute inset-0 flex flex-col justify-center items-center space-y-4">
            <h1 className="text-white text-3xl font-bold">{t('DiscoverTheNew')}</h1> {/* Title on top of the button */}
            <h2 className="text-white text-3xl font-bold">Alpha Lyrae 950</h2> {/* Title on top of the button */}
            <HoverButton
                config={config}
                onClick={() => navigate(`/projects/classe950`)}
            >{t('DiscoverMore')}</HoverButton>
            </div>

        </div>
        )}

        {/*  //////////////////////////////////       GALLERY      /////////////////////////////////////// */}
        {config.mainGallery.length <= 0 &&
            <Stack
                direction='h'
                enableScrollButtons='true'
                style={{width:'100%'}}
                config={config}
                autoScroll={true}>
                    {projectGallery.map((image,index) => (
                        <div key={index}
                        style={{
                            width: isMobile ? 'calc(90vw - 20px)' : '',height: isMobile ? '' : '40vh', margin:isMobile ?'10px 10px':'10px 30px',aspectRatio: '16/9'
                        }}>
                            <img src={image} alt={'gallery'}
                            className='object-cover rounded-[30px] shadow-[-2px_-2px_10px_rgba(255,_255,255,_0.1),_2px_5px_5px_rgba(0,0,0,_0.2)] '
                            style={{
                                    aspectRatio: '16/9',
                                    height: '100%',
                            }}
                        />
                    </div>
                ))}
        </Stack>}
      {/*  //////////////////////////////////       OUR SERVICES       /////////////////////////////////////// */}
      <Stack className={'ourServices'} direction="auto" style={{width:'100%'}} title={t('OurServices')} titleLevel='h1' titleStyle={{color:"white"}} enableScrollButtons='true' config={config}>
        {config.cardsData.map(card => (
          <HoverCard
            key={card.id}
            image={card.image}
            title={card.title}
            description={card.description}
            config={config}
            direction='v'
            //buttonText={card.buttonText}
            onButtonClick={() => console.log('Clicked', card.title)}
            imageStyle={{maxWidth: isMobile ? '35vw' : '200px',...(isMobile ? { width: '100%'} : {}),margin:'auto'}}
            style={{backgroundColor:'rgba(255,255,255,0.95)', width: isMobile ? "90vw" : '',maxWidth: isMobile ? '' : '300px'}}
            itemsStyle={{margin:'auto',width:'100%',height:'100%'}}
          />
        ))}
      </Stack>
      {/*  //////////////////////////////////       OUR TEAM       /////////////////////////////////////// */}

      <Stack className={'ourTeam'} direction="auto" style={{ width:'100%'}} title={t('OurTeam')} titleLevel='h1' titleStyle={{color:"white"}}>
        {config.teamCardsData.map(card => (
          <HoverCard
            key={card.id}
            image={card.image}
            title={card.title}
            description={card.description}
            config={config}
            buttonText={t('ContactUs')}
            imageStyle={{width:"150px"}}
            style={{backgroundColor:'rgba(255,255,255,0.95)'}}
            onButtonClick={() => console.log('Clicked', card.title)

            }
          />
        ))}
      </Stack>
    </Stack>

    );
}

export default Home;

// Repeat for Gallery.js and Contact.js with appropriate content changes
