// src/translations.js
const translations = {
    DiscoverMore: {
      it: "Scopri di più",
      en: "Discover More",
      es: "Descubre más",
      fr: "En savoir plus",
      de: "Mehr erfahren"
    },
    DiscoverTheNew: {
        it: "Scopri il nuovo",
        en: "Discover the new",
        es: "Descubre lo nuevo",
        fr: "Découvrez le nouveau",
        de: "Entdecke das Neue"
      },
    Welcome: {
      it: "Benvenuto nel nostro sito!",
      en: "Welcome to our website!",
      es: "¡Bienvenido a nuestro sitio web!",
      fr: "Bienvenue sur notre site!",
      de: "Willkommen auf unserer Website!"
    },
    OurServices: {
      it: "I nostri servizi",
      en: "Our services",
      es: "Nuestros servicios",
      fr: "Nos services",
      de: "Unsere Dienstleistungen"
    },
    OurTeam: {
      it: "Il nostro team",
      en: "Our Team",
      es: "Nuestro equipo",
      fr: "Notre équipe",
      de: "Unser Team"
    },
    ContactUs: {
      it: "Contatto",
      en: "Contact",
      es: "Contacto",
      fr: "Contact",
      de: "Kontakt"
    },
    Gallery: {
      it: "Galleria",
      en: "Gallery",
      es: "Galería",
      fr: "Galerie",
      de: "Galerie"
    },
    MoreInfo: {
      it: "Maggiori informazioni",
      en: "More Info",
      es: "Más información",
      fr: "Plus d'informations",
      de: "Mehr Informationen"
    },
    PageCount: {
        it: "di",
        en: "of",
        es: "de",
        fr: "sur",
        de: "von"
      }
  };

  export default translations;
