import React, { useState } from 'react';
import Stack from './Stack';
import config from '../assets/config';
import Gallery from './Gallery';
import HoverCard from './HoverCard';
import Viewer from './3dViewer2';
import Card from './Card';
import useResponsive from './useResponsive';
import ExampleWrapper from './SpringModal';
function ProjectPage({ project }) {
    const isMobile = useResponsive();
    const [markdownFileContent, setMarkdownFileContent] = useState('');
    if (!project) {
        return <div>Project not found</div>;
    }

    const fetchMarkdown = (filePath) => {
        if(!filePath) return;
        if(markdownFileContent) return;
        fetch(filePath)
          .then(response => response.text())
          .then(text => setMarkdownFileContent(text))
          .catch(error => console.error('Error loading the markdown file:', error));

    };
    fetchMarkdown(`${process.env.PUBLIC_URL}/content/projects/${project.nameid}/${project.markdownFile}`);

    const projectGallery = project.gallery.map(image => `${process.env.PUBLIC_URL}/content/projects/${project.nameid}/${image}`);
    const project3dModel = `${process.env.PUBLIC_URL}/content/projects/${project.nameid}/3dModels/model.glb`;
    return (
        <Stack
            direction="v"
            style={{marginTop:'10px',width:'100%'}}
            title={project.title}
            titleStyle={{color:'white'}}
            justifyContent='center'
            parentStyle={{display:'flex',justifyContent:'center'}}
        >
            {/*  //////////////////////////////////       GALLERY      /////////////////////////////////////// */}
            {config.mainGallery.length <= 0 &&
                <Stack
                    direction='h'
                    enableScrollButtons='true'
                    style={{width:'100%'}}
                    config={config}
                    autoScroll={true}>
                        {projectGallery.map((image,index) => (
                            <div key={index}
                            style={{
                                width: isMobile ? 'calc(90vw - 20px)' : '',height: isMobile ? '' : '50vh', margin:isMobile ?'10px 10px':'10px 30px',aspectRatio: '16/9'
                            }}>
                                <img src={image} alt={'gallery'}
                                className='object-cover rounded-[30px] shadow-[-2px_-2px_10px_rgba(255,_255,255,_0.1),_2px_5px_5px_rgba(0,0,0,_0.2)] '
                                style={{
                                        aspectRatio: '16/9',
                                        height: '100%',
                                }}
                            />
                        </div>
                    ))}
            </Stack>}
            {/*  //////////////////////////////////       MARKDOWN       /////////////////////////////////////// */}

            <Stack direction='auto'
            style={{width:'100%'}}
            >
            {!project.markdown && project.markdownFile &&
                <Card config={config} style={{width: isMobile ? '90vw' : ''}} direction='h' description={markdownFileContent}/>
            }
            {/* {project3dModel && <Viewer modelUrl={project3dModel}/>} */}
            {project3dModel && <Viewer modelUrl={project3dModel}
            style={{
                width: isMobile ? '90vw' : '100%',
                padding: isMobile ? '0px' : '10px',
                height: isMobile ? '50vh' : '100%',
                minWidth: isMobile ? '' : '30vw',
            }}/> }
            </Stack>
            {project.gallery && <Gallery style={{}} modalEnabled={true} images={projectGallery}> </Gallery>}

        </Stack>
    );
}

export default ProjectPage;
